import React,{useEffect, useState, Fragment} from 'react'
import './../css/BundleStyle.css'


const BundleCard=()=>{

return(
<div className="bundle-card-list">
	<article className="bundle-card">
	<figure className="bundle-card-image">
		<img src="https://cdn.sanity.io/images/dqllnil6/production/2f15086fc48b3b3bb43423567d59c2d24796d50c-1600x1178.jpg?w=1200&q=60&auto=format" alt="An orange painted blue, cut in half laying on a blue background" />
	</figure>
	<div className="bundle-card-header">
		<p className="header-title" href="#">When life gives you oranges</p>
	</div>
	<div className="bundle-card-footer">
		<div className="bundle-card-meta bundle-card-meta--views">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" display="block" id="EyeOpen">
				<path d="M21.257 10.962c.474.62.474 1.457 0 2.076C19.764 14.987 16.182 19 12 19c-4.182 0-7.764-4.013-9.257-5.962a1.692 1.692 0 0 1 0-2.076C4.236 9.013 7.818 5 12 5c4.182 0 7.764 4.013 9.257 5.962z" />
				<circle cx="12" cy="12" r="3" />
			</svg>
			2,465
		</div>
		<div className="bundle-card-meta bundle-card-meta--date">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" display="block" id="Calendar">
				<rect x="2" y="4" width="20" height="18" rx="4" />
				<path d="M8 2v4" />
				<path d="M16 2v4" />
				<path d="M2 10h20" />
			</svg>
			Jul 26, 2019
		</div>
	</div>
</article>
</div>
)
}

export default BundleCard