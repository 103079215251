import React from 'react';
import { Button, LinearProgress } from '@mui/material';
import { Avatar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
//import FeatherIcon from '@mui/icons-material/Feather';
import { ArrowRight } from 'react-feather';
import { COLORS } from '../constants';
import { useHistory } from 'react-router-dom';


const ItemCard = ({ house }) => {
  const history = useHistory();

  const handleButtonClick = () => {

    // Navigate to the /raffle-details page
    history.push({
      pathname: `/raffle-details/${house.id}`,
      state: { house },
    });
  };

  return (
    <div className="card" style={{ marginBottom: 20 }}>
      {/* House image */}
      <img
        src={`https://api.bol-lo.com/api/v1/media/raffle_games/${house.images}`}
        alt={house.title}
        className="cardImage"
        style={{ width: '100%', height: '400px', borderRadius:8, border:'2px solid lightgrey' }}
      />

      <div className="actionContainer" style={{ position: 'absolute', width: '100%', top: 0, display: 'flex', justifyContent: 'space-between' }}>
        <Avatar
          alt="BOL-LO Logo"
          src="/img/logo.png"
          sx={{ margin: '12px', backgroundColor: 'rgba(255,255,255,0.9)', borderRadius: '50px', border:'2px solid white'  }}
        />


      </div>
      <div className="actionContainer" style={{position: 'absolute', width: '100%', bottom: 125, display: 'flex', justifyContent: '', alignItems:'center'}}>
        <Avatar
        
          alt="packages raffle type"
          sx={{ margin: '12px', backgroundColor: 'rgba(255,255,255,0.9)', borderRadius: '50px', border:'2px solid #ededed'  }}
        >
          <svg
    width="32px"
    height="32px"
    viewBox="0 0 1.44 1.44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m0.781 0.175 0.354 0.157c0.102 0.045 0.102 0.119 0 0.164l-0.354 0.157c-0.04 0.018 -0.106 0.018 -0.146 0l-0.354 -0.157c-0.102 -0.045 -0.102 -0.119 0 -0.164l0.354 -0.157c0.04 -0.018 0.106 -0.018 0.146 0"
      stroke="#292D32"
      strokeWidth={0.09}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M0.18 0.66c0 0.05 0.038 0.109 0.084 0.129l0.407 0.181c0.031 0.014 0.067 0.014 0.097 0l0.407 -0.181c0.046 -0.02 0.084 -0.079 0.084 -0.129"
      stroke="#292D32"
      strokeWidth={0.09}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M0.18 0.96c0 0.056 0.033 0.106 0.084 0.129l0.407 0.181c0.031 0.014 0.067 0.014 0.097 0l0.407 -0.181c0.051 -0.023 0.084 -0.073 0.084 -0.129"
      stroke="#292D32"
      strokeWidth={0.09}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
        </Avatar>

<span className='package-description' > 3 Items included</span>
      </div>
      <div className="counter" style={{ position: 'absolute', bottom: 150, flexDirection: 'row', justifyContent: 'flex-start' }}>

      </div>

      <div style={{ marginTop: 24, marginBottom: 24 }}>
        {/* Title and price container */}
        <div style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, margin: '0 8px' }}>
          <span style={{ fontSize: 16, fontWeight: 'bold', color: COLORS.white }}>
            {house.title}
          </span>

        </div>
      </div>

      <div style={{ marginBottom: 16 }}>
        {/* Title and price container */}
        <div style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10, margin: '0 8px' }}>


          <Button
            onClick={handleButtonClick}
            sx={{ borderRadius: '50px' }}
            style={{ background: '#FFF'}}
          >
            <span style={{color: "#292D32", fontWeight:600, fontSize:'1.5rem'  }}>More details</span>

          </Button>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
