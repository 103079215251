import * as React from "react";
const SuccessIcon = (props) => (
  <svg
    fill="#fff"
    width="250px"
    height="250px"
    viewBox="0 0 24 24"
    id="check-mark-circle"
    data-name="Line Color"
    xmlns="http://www.w3.org/2000/svg"
    className="icon line-color"
    {...props}
  >
    <polyline
      id="secondary"
      points="8 11.5 11 14.5 16 9.5"
      style={{
        fill: "none",
        stroke: "rgb(255, 255, 255)",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
    <rect
      id="primary"
      x={3}
      y={3}
      width={18}
      height={18}
      rx={9}
      style={{
        fill: "none",
        stroke: "rgb(255, 255, 255)",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
  </svg>
);
export default SuccessIcon;
