import { Image } from "./image";
import React, { Fragment } from "react";
import { useParams,Link } from "react-router-dom";
import FailureIcon from "./failureIcon";
import SuccessIcon from "./succesIcon";
import './../css/paymentStatus.css';

export const PaymentStateView = () => {
  const status = true;
  return (
    <Fragment style={{padding:50}}>
      {status && (
        <div className="card-container">
          <section className="card">
            <div class="rt-container">
              <div class="col-rt-12">
                <div class="Scriptcontent">
                  <div id="card" class="animated fadeIn">
                    <div id="upper-side-success">
                    <SuccessIcon />
                      <h3 id="status">Payment succeed</h3>
                    </div>
                    <div id="lower-side">
                      <p id="message">
                        Congratulations, your ticket has been successfully
                        purchased.
                      </p>
                      <Link to={'/'} id="contBtn">
                      Continue
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      {!status && (
        <div className="card-container">
        <section className="card">
          <div class="rt-container">
            <div class="col-rt-12">
              <div class="Scriptcontent">
                <div id="card" class="animated fadeIn">
                  <div id="upper-side-failure">
                  <FailureIcon />
                    <h3 id="status-failure">Payment failed</h3>
                  </div>
                  <div id="lower-side">
                    <p id="message">
                      Congratulations, your ticket has been successfully
                      purchased.
                    </p>
                    <Link to={'/'} id="contBtnFailure">
                      Continue
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      )}
    </Fragment>
  );
};
