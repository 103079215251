import React, { useEffect, useState } from 'react';
import BundleCard from './BundleCard';
import axios from 'axios';
import CustomAlert from './CustomAlert';
import { Link  } from 'react-router-dom';

export const Services = (props) => {
  const [raffles, setRaffles] = useState([]);

  const handleReload = () => {
    // Handle reload action
    getRaffles();
  };

  const getRaffles = async () => {
    try {
      let basicUrl = 'https://api.bol-lo.com/api/v1/raffle-games';
      let res = await axios.get(basicUrl);
      setRaffles(res.data.models);

      // Check if the raffles array is empty
      if (res.data.models.length === 0) {
        CustomAlert(
          'No Raffles Found',
          'There are no raffles available. Please try again later or reload the page.',
          {
            onClose: handleReload,
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRaffles();
  }, []);

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Buy The packgage</h2>
        </div>
        <div className='row'>
           {' Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo quasi eos dolorum, repudiandae commodi excepturi obcaecati in aspernatur modi neque quas blanditiis dolor recusandae aliquam iusto quod ad eum accusantium? Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique ex excepturi accusamus aliquid, quae tempora perspiciatis officia non natus quidem, ad nam pariatur tempore ratione vero, quod sed quia veniam!'}
        </div>
        <div className="row">
          {/* Map over the raffles array and render an ItemCard for each item */}
          {raffles.map((raffle) => (
            <div key={raffle.id} className="col-md-4">
              <BundleCard />
            </div>
          ))}
        </div>
        <div className='row'>
        <div className="btn-wrapper-package">
                    
                      <Link to="/checkout" className="btn-custom btn-submit-package">
                        <span style={{ fontSize: '16px', color: 'white', fontWeight: 'bold' }}>
                          Checkout
                        </span>
                      </Link>
                  
                  </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

