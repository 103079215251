export default {
    gradientForm: '#DA0037',
    primary: '#242325',
    white: '#FFFFFF',
    dark: '#242325',
    bgColor: '#82ccdd',
    warning: '#f0d500',
    danger: '#CE2029',
    gray: '#666666',
    grey:'#313131',
    darkLight:'#524f54',
    grayLight: '#ccc',
    black: '#0a0a0a',
    ghostWhite:"#F6F7FB",
    aliceBlue:"#E8F2F9",
    primaryOrange:"#E47048",
    lowGrey:'#EDEDED'
  };