import React, { useEffect, useState } from 'react';
import ItemCard from './ItemCard';
import axios from 'axios';
import CustomAlert from './CustomAlert';

export const Services = (props) => {
  const [raffles, setRaffles] = useState([]);

  const handleReload = () => {
    // Handle reload action
    getRaffles();
  };

  const getRaffles = async () => {
    try {
      let basicUrl = 'https://api.bol-lo.com/api/v1/raffle-games';
      let res = await axios.get(basicUrl);
      setRaffles(res.data.models);

      // Check if the raffles array is empty
      if (res.data.models.length === 0) {
        CustomAlert(
          'No Raffles Found',
          'There are no raffles available. Please try again later or reload the page.',
          {
            onClose: handleReload,
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRaffles();
  }, []);

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Shop</h2>
        </div>
        <div className="row">
          {/* Map over the raffles array and render an ItemCard for each item */}
          {raffles.map((raffle) => (
            <div key={raffle.id} className="col-md-4">
              <ItemCard house={raffle} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;

