import React, { useEffect, useState } from "react";
import { Link , useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import CustomAlert from './CustomAlert';
import {
  TextField
} from "@mui/material";

const RaffleDetails = (props) => {
  const history = useHistory();
  const { raffleId } = useParams();
  const [raffle, setRaffle] = useState({});
  const [qtyValue, setQtyValue] = useState(1);

  // const [price, setPrice] = useState(50);

  const handleReload = () => {
    // Handle reload action
    getRaffle();
  };

  const handleChange = (event) => {
    // console.log(event.target.value);
    setQtyValue(event.target.value);
  };

  const handleCheckout = () => {

    const totalPrice = qtyValue * raffle.price_ticket;

    // Redirect to the /checkout route
    history.push('/checkout', { totalPrice, qtyValue, raffleId });
  };

  const getRaffle = async () => {
    try {
      let basicUrl = `https://api.bol-lo.com/api/v1/raffle-games/${raffleId}`;

      let res = await axios.get(basicUrl);

      // Check if the raffles array is empty
      if (!res.data) {
        CustomAlert(
          'No Raffle Found',
          'There are no raffle available. Please try again later or reload the page.',
          {
            onClose: handleReload,
          }
        );
      }

      setRaffle(res.data);

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRaffle();
  }, []);

  const renderParagraph = () => {

    let paragraph = raffle.description || 'N/A';

    const formattedParagraph = paragraph.replace(/<br\/>/g, '<br\>');

    return <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: formattedParagraph }} />;
  };

  const renderMission = () => {
    if (!props.data) {
      return <p>Loading...</p>;
    }

    const formattedParagraph = props.data.mission.replace(/<br\/>/g, '<br\>');

    return <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: formattedParagraph }} />;
  };

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src={`https://api.bol-lo.com/api/v1/media/raffle_games/${raffle.images || 'N/A' }`} className="img-responsive" alt=""
              style={{ width: '100%', height: '500px', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
            />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{raffle.title || 'N/A'}</h2>
              {renderParagraph()}
              <h3>Raffle price: ${raffle.price_ticket || 'N/A'}</h3>


              <ul>

                  <div>
                    <TextField
                      id="outlined-number"
                      style = {{marginTop: -10, width: 100}}
                      onChange={handleChange}
                      label=""
                      value={qtyValue}
                      InputProps={{ inputProps: { min: 1, max: 500} }}
                      name="qtybutton"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </div>

                  <br/>
                  <div className="btn-wrapper">
                    <button id="submit" className="theme-btn-2 btn btn-effect-2" onClick={handleCheckout}>
                      <span style={{ fontSize: '16px', color: 'white', fontWeight: 'bold' }}>
                        Checkout
                      </span>
                    </button>
                  </div>



              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaffleDetails;
