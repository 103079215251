import React from "react";

export const About = (props) => {

  const renderParagraph = () => {
    if (!props.data) {
      return <p>Loading...</p>;
    }

    const formattedParagraph = props.data.paragraph.replace(/<br\/>/g, '<br\>');

    return <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: formattedParagraph }} />;
  };

  const renderMission = () => {
    if (!props.data) {
      return <p>Loading...</p>;
    }

    const formattedParagraph = props.data.mission.replace(/<br\/>/g, '<br\>');

    return <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: formattedParagraph }} />;
  };

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              {renderParagraph()}
              <h3>Our mission</h3>
              <div className="list-style">
                {renderMission()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
