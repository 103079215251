import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Navigation } from './components/navigation';
import { Header } from './components/header';
import { About } from './components/about';
import { Services } from "./components/services";
import { Team } from './components/Team';
import { Contact } from './components/contact';
import { Checkout } from './components/checkout'
import { PaymentStateView } from './components/paymentStateView';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JsonData from "./data/data.json";
import RaffleDetails from './components/RaffleDetails'; // Import the new component
import BundleCardView from './components/BundleCardView';

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {

    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Navigation data={landingPageData.Navigation} />
        <Header data={landingPageData.Header} />

        <Switch>
          <Route path="/" exact>
            <About data={landingPageData.About} />
             <Services data={landingPageData.Services} />
            <Team data={landingPageData.Team} />
            <Contact data={landingPageData.Contact} />
          </Route>
          {/* Add a new route for RaffleDetails */}
          <Route path="/raffle-details/:raffleId" component={RaffleDetails} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/success" component={PaymentStateView} />
          <Route path='/bundlecard' component={BundleCardView} />
        </Switch>

        <ToastContainer />
      </div>
    </Router>
  );
};

export default App;



