import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from 'react-router-dom';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import "./../css/CheckoutForm.css";

const stripePromise = loadStripe("pk_test_51NW6IjLIsNBKyDQwlalulGcR92UYPApjFYF4nLnoCjijpMiarvNrVnE0XfvhbiHPXvcLmFYkjNfcznyyDgGlFsHW00HgddzYro");

export const Checkout = () => {
  const location = useLocation();
  const { totalPrice, qtyValue, raffleId } = location.state; // Adjust according to your data structure
  const [clientSecret, setClientSecret] = useState("");
  const [billingInfo, setBillingInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
  });
  const [open, setOpen] = useState(false);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  // Event handler to update billing information state
  const handleBillingInfoChange = (e) => {
    const { name, value } = e.target;
    setBillingInfo({ ...billingInfo, [name]: value });
  };

  // Validation function
  const validateForm = () => {
    if (!billingInfo.first_name || !billingInfo.last_name || !billingInfo.email) {
      alert('Please fill in all required fields.');
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(billingInfo.email)) {
      alert('Please enter a valid email address.');
      return false;
    }
    return true;
  };

  // Event handler for form submission to get client secret
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const raw = JSON.stringify({
        "ticketQuantity": qtyValue,
        "totalPrice": totalPrice,
        "raffle_game_id": raffleId,
        "email": billingInfo.email,
        "phone_number": billingInfo.phone,
        "first_name": billingInfo.first_name,
        "last_name": billingInfo.last_name
      });

      const requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: 'follow'
      };

      fetch("https://api.bol-lo.com/api/v1/pay-tickets", requestOptions)
        .then(response => response.json())
        .then((data) => {
          const { clientSecret } = data;
          setClientSecret(clientSecret);
          setOpen(true); // Open the dialog when we have the client secret
        })
        .catch(error => console.log('error', error));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="ltn__checkout-area mb-105" style={styles.checkoutArea}>
      <form onSubmit={handleFormSubmit} style={styles.centeredForm}>
        <div className="row">
          {/* Billing Details Column */}
          <div className="col-lg-6">
            <div className="ltn__checkout-inner">
              <div style={styles.checkOutSingleContent}>
                <h4 style={styles.title}>Billing Details</h4>
                <div style={styles.checkOutSingleContentInfo}>
                  {/* Personal Information */}
                  <h6 style={styles.typography6}>Personal Information</h6>
                  <div style={{ ...styles.row, ...styles.container }}>
                    {/* First Name */}
                    <div style={styles.colMd6}>
                      <div style={styles.input}>
                        <input
                          type="text"
                          name="first_name"
                          placeholder="First name"
                          value={billingInfo.first_name}
                          onChange={handleBillingInfoChange}
                          style={styles.inputStyle}
                        />
                      </div>
                    </div>
                    {/* Last Name */}
                    <div style={styles.colMd6}>
                      <div className="input-item input-item-name ltn__custom-icon">
                        <input
                          type="text"
                          name="last_name"
                          placeholder="Last name"
                          value={billingInfo.last_name}
                          onChange={handleBillingInfoChange}
                          style={styles.inputStyle}
                        />
                      </div>
                    </div>
                    {/* Email */}
                    <div style={styles.colMd6}>
                      <div className="input-item input-item-email ltn__custom-icon">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email address"
                          value={billingInfo.email}
                          onChange={handleBillingInfoChange}
                          style={styles.inputStyle}
                        />
                      </div>
                    </div>
                    {/* Phone */}
                    <div style={styles.colMd6}>
                      <div className="input-item input-item-phone ltn__custom-icon">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Phone number"
                          value={billingInfo.phone}
                          onChange={handleBillingInfoChange}
                          style={styles.inputStyle}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Address */}
                  <div className="">
                    <h6 style={styles.typography6}>Address</h6>
                    <div style={{ ...styles.row, ...styles.container }}>
                      {/* Address */}
                      <div style={styles.colMd6}>
                        <div style={styles.input}>
                          <input
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={billingInfo.address}
                            onChange={handleBillingInfoChange}
                            style={styles.inputStyle}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button type="submit" style={styles.submitButton}>Submit</Button>
                </div>
              </div>
            </div>
          </div>

          {/* Purchase Details Column */}
          <div className="col-lg-6">
            <div style={styles.checkOutSingleContent}>
              <h4 style={styles.title}>Purchase Details</h4>
              <div style={styles.checkOutSingleContentInfo}>
                <p>Total Price: ${totalPrice}</p>
                <p>Total Ticket(s): {qtyValue}</p>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Payment Method</DialogTitle>
        <DialogContent>
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm clientSecret={clientSecret} billingInfo={billingInfo} />
            </Elements>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

const styles = {
  checkoutArea: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  centeredForm: {
    maxWidth: "800px",
    width: "100%",
    background: "#fff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  },
  checkOutSingleContent: {
    marginTop: 50,
    marginBottom: 30,
  },
  checkOutSingleContentInfo: {
    padding: "30px",
    border: "1px solid #e5eaee",
  },
  container: {
    marginLeft: "-15px",
    marginRight: "-15px",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "calc(var(0) * -1)",
    marginRight: "calc(var(3.5rem) * -.5)",
    marginLeft: "calc(var(3.5rem) * -.5)",
  },
  title: {
    marginBottom: "30px",
    fontSize: "22px",
    fontWeight: "700",
    borderLeft: "2px solid",
    borderColor: "#ff5a3c",
    paddingLeft: "10px",
  },
  form: {
    padding: "2rem",
  },
  colMd6: {
    flexShrink: "0",
    width: "100%",
    maxWidth: "100%",
    paddingRight: "calc(var(1.5rem) * .5)",
    paddingLeft: "calc(var(1.rem) * .5)",
    marginTop: "var(0)",
    position: "relative",
    "@media (min-width: 768px)": {
      flex: '0 0 auto',
      width: '50%'
    }
  },
  input: {
    position: "relative",
  },
  typography6: {
    color: "#071c1f",
    clear: "both",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "700",
    lineHeight: "1.3",
    marginBottom: "15px",
  },
  inputStyle: {
    backgroundColor: "#FFF",
    border: "2px solid",
    borderColor: "#e5eaee",
    height: "65px",
    WebkitBoxShadow: "none",
    boxShadow: "none",
    paddingLeft: "20px",
    fontSize: "16px",
    color: "#5c727d",
    width: "100%",
    marginBottom: "30px",
    borderRadius: "0",
    paddingRight: "40px",
  },
  submitButton: {
    backgroundColor: "#ff5a3c",
    color: "#FFF",
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    borderRadius: "5px",
    marginTop: "20px",
  },
};
